angular
  .module("sq.jobs.model", ["ngResource", "sq.config", "sq.services.socketIO"])
  .factory("Jobs", JobsFactory);

// TODO: I think I'm not modeling things very well. Feels overly specific (see schema)
// Should this package know the shape of the entire job data graph?
// TODO: improve error handling, using go-style (err, result);
function JobsFactory($resource, $log, $http, $q, SETTINGS, _) {
  const baseUrl = SETTINGS.apiEndpoint + "jobs/";

  const JobsModel = $resource(
    `${baseUrl}:_id/:action/:visibility`,
    {
      _id: "@_id",
      action: "@action"
    },
    _cActions()
  );

  const submissionSchema = {
    _id: "",
    state: "",
    attempts: 0,
    log: {
      progress: 0,
      skipped: 0,
      messages: [],
      exceptions: []
    },
    queueID: "",
    queueStats: {
      // Provide some undefined values
      ttr: -9,
      age: -9
    },
    // A date
    date: null,
    type: "",
    addedFileNames: [""]
  };

  const schema = {
    _id: "", //must be empty OR filled with a unique value; if set to value, will be used server-side
    assembly: "",
    email: "",
    // The YAML config the annotator worker uses for this job
    config: {},
    options: {
      index: true
    },
    inputFileName: "",
    // A job may be created form a query rather than an inputFilePath
    inputQuery: "",
    //A file prefix for the tarball that contains the file
    //This is what the name the job
    outputBaseFileName: "",
    name: "",
    results: {},
    submission: Object.assign({}, submissionSchema),
    search: {
      activeSubmission: Object.assign({}, submissionSchema),
      archivedSubmissions: [Object.assign({}, submissionSchema)],
      // The fields indexed for this job
      fieldNames: [],
      indexName: "",
      indexType: "",
      savedResults: [Object.assign({}, this)],
      queries: [
        {
          queryType: "",
          queryValue: ""
        }
      ]
    },
    type: "",
    _creator: "",
    forks: {},
    // Expects a date object
    expireDate: null,
    actionsTaken: []
  };

  // core, required data to submit a job, track progress, view results
  // TODO: get schema from server

  //TODO: make the schema properties immutable using Object.defineProperties
  JobsModel.newSubmission = function instance(creatorID) {
    const sInst = Object.assign({}, schema);
    // The submission is created by the server
    delete sInst.submission;
    // Can't search until you've created the job
    delete sInst.search;

    if (!creatorID) {
      delete sInst._creator;
    } else {
      sInst._creator = creatorID;
    }

    // this is set by the expireDate
    delete sInst.expireDate;

    // no actions taken yet
    delete sInst.actionsTaken;

    delete sInst.forks;

    delete sInst.config;

    return new JobsModel(sInst);
  };

  Object.defineProperties(JobsModel, {
    schema: {
      get: () => schema
    }
  });

  JobsModel.prototype.getSamples = function() {
    if (!(this.results && this.results.stats)) {
      return null;
    }

    // Within samples we also have "total", so remove that
    return (
      (this.results.stats && this.results.stats.samples) ||
      Object.keys(this.results.results.samples)
    );
  };

  JobsModel.prototype.getNumSamples = function() {
    if (!(this.results && this.results.stats)) {
      return null;
    }

    // Within samples we also have "total", so remove that
    return (
      (this.results.stats && this.results.stats.samples) ||
      Object.keys(this.results.results.samples).length - 1
    );
  };

  JobsModel.prototype.clear = function clear() {
    angular.merge(this, JobsModel.newSubmission());
  };

  JobsModel.prototype.clearId = function clear() {
    this._id = "";
  };

  JobsModel.prototype.isSubmitted = function isSubmitted() {
    return this.submission.state === "submitted";
  };

  JobsModel.prototype.isCompleted = function isCompleted() {
    return this.submission.state === "completed";
  };

  JobsModel.isFailed = function(jobObj) {
    return jobObj.submission.state === "failed";
  };

  JobsModel.prototype.isFailed = function isFailed() {
    return this.submission.state === "failed";
  };

  JobsModel.prototype.isStarted = function isCompleted() {
    return this.submission.state === "started";
  };

  JobsModel.isIncomplete = function(jobObj) {
    return (
      jobObj.submission.state === "started" ||
      jobObj.submission.state === "submitted"
    );
  };

  JobsModel.prototype.isDeleted = function isDeleted() {
    return this.type === "deleted";
  };

  JobsModel.prototype.isArchived = function isDeleted() {
    return this.type === "archived";
  };

  JobsModel.prototype.isIncomplete = function isCompleted() {
    return (
      this.submission.state === "started" ||
      this.submission.state === "submitted"
    );
  };

  JobsModel.prototype.isPublic = function isPublic() {
    return this.visibility === "public";
  };

  JobsModel.prototype.isIndexArchived = function isIndexArchived() {
    return (
      this.search &&
      !this.search.activeSubmission &&
      this.search.archivedSubmissions.length > 0
    );
  };

  JobsModel.prototype.isIndexSubmitted = function isIndexSubmitted() {
    if (!this.hasActiveSubmission()) {
      return false;
    }

    return this.search.activeSubmission.state === "submitted";
  };

  JobsModel.prototype.isIndexStarted = function isIndexStarted() {
    if (!this.hasActiveSubmission()) {
      return false;
    }

    return this.search.activeSubmission.state === "started";
  };

  JobsModel.prototype.isIndexFailed = function isIndexFailed() {
    if (!this.hasActiveSubmission()) {
      return false;
    }

    return this.search.activeSubmission.state === "failed";
  };

  JobsModel.prototype.isIndexGone = function isIndexGone() {
    if (!this.hasActiveSubmission()) {
      return false;
    }

    return this.search.activeSubmission.state === "gone";
  };

  JobsModel.prototype.isIndexCompleted = function isIndexCompleted() {
    if (!this.hasActiveSubmission()) {
      return false;
    }

    return this.search.activeSubmission.state === "completed";
  };

  JobsModel.prototype.setSubmitted = function() {
    this.submission.state = "submitted";
  };

  JobsModel.prototype.setStarted = function() {
    this.submission.state = "started";
  };

  JobsModel.prototype.setJobID = function(jobID) {
    if (typeof jobID !== "string" && typeof jobID !== "number") {
      $log.error("jobID must be string, or number");
      return;
    }
    this._id = jobID;
  };

  JobsModel.prototype.updateSubmissionProgress = function(submissionObj) {
    if (!_.isObject(submissionObj)) {
      var err = new Error("data provided must be object, got: ", submissionObj);
      log.error(err);

      return err;
    }

    this.submission = Object.assign({}, submissionObj);
  };

  JobsModel.prototype.updateActiveSearchIndex = function(searchIndexObj) {
    if (!_.isObject(searchIndexObj)) {
      const err = new Error(
        "data provided must be object, got: ",
        searchIndexObj
      );

      $log.error(err);

      return err;
    }

    if (!this.search) {
      this.search = {};
    }

    this.search.activeSubmission = Object.assign({}, searchIndexObj);

    return null;
  };

  JobsModel.prototype.hasActiveSubmission = function() {
    if (!(this.search && this.search.activeSubmission)) {
      return false;
    }

    return Object.keys(this.search.activeSubmission).length > 0;
  };

  JobsModel.prototype.setIndexMissing = function() {
    if (!this.hasActiveSubmission()) {
      return;
    }

    // For now, not mutation on server;
    this.search.activeSubmission.state = "missing";
  };

  JobsModel.prototype.isIndexMissing = function() {
    if (!this.hasActiveSubmission()) {
      return false;
    }

    // For now, not mutation on server;
    return this.search.activeSubmission.state === "missing";
  };

  JobsModel.prototype.setSubmitted = function() {
    this.submission.state = "submitted";
  };

  JobsModel.prototype.setStarted = function() {
    this.submission.state = "started";
  };

  JobsModel.prototype.setJobID = function(jobID) {
    if (typeof jobID !== "string" && typeof jobID !== "number") {
      $log.error("jobID must be string, or number");
      return;
    }
    this._id = jobID;
  };

  JobsModel.prototype.setEmail = function(email) {
    if (typeof email !== "string") {
      $log.error("email must be string, got", typeof email);
      return;
    }

    this.email = email;
  };

  JobsModel.prototype.setAssembly = function(assembly) {
    if (typeof assembly !== "string") {
      $log.error("assembly must be string got", typeof assembly);
      return;
    }

    this.assembly = assembly;
  };

  JobsModel.prototype.setOptions = function(options) {
    if (typeof options !== "object") {
      $log.error("options must be object got ", typeof options);
      return;
    }

    if (!options.Basic && options.Advanced) {
      $log.error("options must have Basic and Advanced properties");
      return;
    }

    this.options = options;
  };

  JobsModel.prototype.updateSubmissionProgress = function(submissionObj) {
    if (!_.isObject(submissionObj)) {
      const err = new Error(
        "data provided must be object, got: ",
        submissionObj
      );

      $log.error(err);

      return err;
    }

    this.submission = Object.assign({}, submissionObj);

    return null;
  };

  JobsModel.prototype.updateActiveSearchIndex = function(searchIndexObj) {
    if (!_.isObject(searchIndexObj)) {
      const err = new Error(
        "data provided must be object, got: ",
        searchIndexObj
      );

      $log.error(err);

      return err;
    }

    if (!this.search) {
      this.search = {
        activeSubmission: {}
      };
    }

    this.search.activeSubmission = Object.assign({}, searchIndexObj);

    return null;
  };

  JobsModel.prototype.setIndexMissing = function() {
    if (!(this.search && this.search.activeSubmission)) {
      return;
    }

    // For now, not mutation on server;
    this.search.activeSubmission.state = "missing";
  };

  JobsModel.prototype.updateSearchData = function(searchData) {
    if (!_.isObject(searchData)) {
      const err = new Error("data provided must be object, got: ", searchData);
      $log.error(err);

      return err;
    }

    this.search = Object.assign({}, searchData);

    return null;
  };

  //with care, assigning by reference, so expects data not to be mutated
  JobsModel.prototype.setComplete = function(data) {
    this.submission.state = "completed";

    if (data.results) {
      this.results = data.results;
    }
  };

  JobsModel.prototype.setFailed = function() {
    this.submission.state = "failed";
  };

  JobsModel.prototype.addMessage = function addMessage(message) {
    if (!message) {
      $log.error("message required in _setMessage");
      return;
    }

    if (!this.submission.log) {
      this.submission.log = angular.merge({}, schema.submission.log);
      $log.warn("Potentially old schema, no log property found");
    }

    if (typeof message === "string") {
      //only one non-descript message type allowed
      this.submission.log.messages.push(message);
    } else if (message && typeof message === "object") {
      //allow things with prototypes
      for (const key in message) {
        if (!message.hasOwnProperty(key)) {
          continue;
        }

        if (this.submission.hasOwnProperty(key)) {
          _combineValues(this.submission, key, message[key]);
        } else if (this.submission.log.hasOwnProperty(key)) {
          _combineValues(this.submission.log, key, message[key]);
        } else {
          $log.warn(key + " not in job model", this);
        }
      }
    } else {
      $log.error("unsupported message provided to job.addMessage", message);
    }

    //update for one way binding? doesn't seem to be needed...
    // this.submission = Object.assign({}, this.submission);
  };

  JobsModel.prototype.setFileName = function(fileName) {
    if (typeof fileName !== "string") {
      $log.error(new Error("filename must be string"));
      return;
    }

    this.inputFileName = fileName;
  };

  JobsModel.prototype.prepareForNewSubmission = function() {
    return {
      assembly: "",
      name: ""
    };
  };

  //mutates this
  JobsModel.prototype.patch = function(data) {
    return $http.post(`${baseUrl}${this._id}`, data).then(response => {
      const { job, paths } = response.data;

      if (job._id !== this._id) {
        $q.reject("id must match in job.patch");
        return;
      }

      paths.forEach(path => _preciseMerge(this, job, path.split(".")));

      return this;
    });
  };

  function _preciseMerge(target = {}, src = {}, fields = []) {
    // if at startup no fields given
    if (!Array.isArray(fields) || fields.length == 0) {
      return;
    }

    const latest = fields.shift();

    if (fields.length == 0) {
      // shallow merge to overwrite
      _.assign(target, src);
      return;
    }

    return _preciseMerge(target[latest], src[latest], fields, true);
  }

  return JobsModel;

  /* private*/
  // TODO: replace incomplete, failed, etc with a single function that accepts
  // an action/type
  function _cActions() {
    return {
      query: {
        method: "GET",
        isArray: true,
        params: {
          _id: "list",
          action: "completed"
        },
        // don't redirect if we're prefetching data
        ignoreAuthModule: true
      },
      incomplete: {
        params: {
          _id: "list",
          action: "incomplete"
        },
        method: "GET",
        isArray: true,
        ignoreAuthModule: true
      },
      failed: {
        params: {
          _id: "list",
          action: "failed"
        },
        method: "GET",
        isArray: true,
        ignoreAuthModule: true
      },
      public: {
        params: {
          _id: "list",
          action: "all",
          visibility: "public"
        },
        method: "GET",
        isArray: true
      },
      shared: {
        params: {
          _id: "list",
          action: "shared"
        },
        method: "GET",
        isArray: true,
        // don't redirect if we're prefetching data
        ignoreAuthModule: true
      },
      restart: {
        method: "GET",
        params: {
          action: "restart"
        }
      },
      checkStatus: {
        method: "GET",
        params: {
          action: "annotationStatus"
        }
      },
      checkIndexStatus: {
        method: "GET",
        params: {
          action: "indexStatus"
        }
      },
      addSynonyms: {
        method: "POST",
        params: {
          action: "addSynonyms"
        }
      },
      reIndex: {
        method: "POST",
        params: {
          action: "reIndex"
        }
      }
      // update: {
      //   method: 'POST',
      // },      // getConfig: {
      //   method: 'GET',
      //   params: {
      //     id: 'config',
      //     action: '@assembly',
      //     cache: true,
      //     transformResponse: function(data) {
      //       console.info('the data is data', data);
      //       console.info('this is', this);
      //     },
      //   }
      // }
    };
  }

  function _combineValues(target, key, value) {
    if (_isPrimitive(value)) {
      if (_isPrimitive(target[key])) {
        target[key] = value;
      } else if (Array.isArray(target[key])) {
        target[key].push(value);
      } else {
        $log.warn(
          "destination is a non-array, non-value, but source is primitive",
          target,
          value
        );
      }
    } else if (Array.isArray(value)) {
      if (Array.isArray(target[key])) {
        target[key] = target[key].concat(value);
      } else {
        $log.warn(
          "destination is not array, but source is array",
          target,
          value
        );
      }
    } else if (value && typeof value === "object") {
      //functions not ok
      //map all keys, could be dangerous, not certain if we should keep
      _.merge(target[key], value);
    }
  }

  function _isPrimitive(value) {
    return (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    );
  }
}

// var _required = ['assembly', 'publicID'];
// JobsModel.verify = function verify(data, cb) {
//   // needs species, assembly, email preference
//   // and check that anything else is an option or discard it
//   // and all values must be strings
//   if (angular.isArray(data) ) {
//     angular.forEach(data, function(value) {
//       angular.forEach(value, _verify);
//     });
//   }else if (angular.isObject(data) ) {
//     angular.forEach(data, _verify);
//   }else {
//     // doesn't test for array of objects, just array of stuffs
//     cb('new job data must be array of objects or object');
//   }

//   function _verify(key) {
//     if(!_required.indexOf(key)) {
//       cb('new job data must include ' + _required.join(',') );
//     }
//   }
//   cb();
// };
